import React from 'react'
import Skills from '../layouts/Skills'
import { about, skillsBar, section2title } from '../../profile'

const About = () => {

    const divStyle = {
        border: '1px solid white'
    };

    return (
        <div id="about" className="effect2">
            <div data-aos="zoom-in-up" data-aos-once="true">
                <div className="d-flex" style={divStyle}>
                    <img src="kursat-blur.png" width={250}/>
                    <div className="about">
                        <div className="About-title-box mt-4">
                            <h1 id="About" className="red-line">{section2title}</h1>
                        </div>
                        <p className="lead about-text">
                            {about.paragraph}
                        </p>
                    </div>
                </div>
            </div>
            <div id="Skills">
                <div className="row d-flex justify-content-center skills">
                    {skillsBar.map((x) =>
                        <Skills key={x.name} faClass={x.faClass} svg={x.svg} label={x.name}/>
                    )}̀
                </div>
            </div>
        </div>
    )
}

export default About
