import React from 'react'
import {contact, section5Title, social} from '../../profile'

const Contact = () => {

    const divStyle = {
        textAlign: 'center'
    };

    return (
        <div className="parallax">
            <div data-aos="zoom-in-up" data-aos-once="true" className="git-form">
                <>
                    <div className="git-head-div text-center mx-auto">
                        <h1 id="Contact" className="git-head">{section5Title}</h1>
                    </div>
                </>
                <div className="container" style={divStyle}>
                    <div className="git-cont row">
                        <div className="col-12 col-sm-6 half my-3">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <div className="inline-block d-flex">
                                    {social.github &&
                                        <a title="Visit Github profile" rel="noopener noreferrer" target="_blank"
                                           href={social.github}><i className="fab fa-github"></i></a>}
                                    {social.linkedin &&
                                        <a title="Visit Linkedin profile" rel="noopener noreferrer" target="_blank"
                                           href={social.linkedin}><i className="fab fa-linkedin"></i></a>}
                                    {social.instagram &&
                                        <a title="Visit Instagram profile" rel="noopener noreferrer" target="_blank"
                                           href={social.instagram}><i className="fab fa-instagram"></i></a>}<br/>
                                </div>
                                {social.resume && <a title="Download Resume" href={social.resume} download><i
                                    className="fas fa-download"></i></a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p id="not-dark" className="Copy">2023 © Copyright <strong>{contact.copyright}</strong>. All Rights Reserved
            </p>
        </div>
    )

}

export default Contact
